import i18n from '../i18n';

const SOFTWARE_PRODUCTS = [
  {
    id: 'qgis',
    name: i18n.locale === 'ru' ? 'NextGIS QGIS' : 'QGIS',
    iconUrl: i18n.locale === 'ru' ? '/static/img/ngqgis_logo.svg' : '/static/img/qgis_logo.svg',
    installerName: 'installer',
    type: 'desktop',
  },
  {
    id: 'formbuilder',
    name: 'Formbuilder',
    iconUrl: '/static/img/ngformbuilder_logo.svg',
    installerName: 'installer',
    type: 'desktop',
  },
  {
    id: 'mobile',
    name: 'Mobile',
    iconUrl: '/static/img/ngmobile_logo.svg',
    installerName: 'nextgis-mobile',
    type: 'mobile',
  },
  {
    id: 'collector',
    name: 'Collector',
    iconUrl: '/static/img/ngcollector_logo.svg',
    installerName: 'nextgis-collector',
    type: 'mobile',
  },
  {
    id: 'tracker',
    name: 'Tracker',
    iconUrl: '/static/img/ngtracker_logo.svg',
    installerName: 'nextgis-tracker',
    type: 'mobile',
  },
];

const MOBILE_NETWORK_ANDROID_INSTALLER_LINK =
  'https://play.google.com/store/apps/details?id=com.nextgis.mobile';
const COLLECTOR_NETWORK_ANDROID_INSTALLER_LINK =
  'https://play.google.com/store/apps/details?id=com.nextgis.collector';
const TRACKER_NETWORK_ANDROID_INSTALLER_LINK =
  'https://play.google.com/store/apps/details?id=com.nextgis.tracker';
const VANILLA_QGIS_NETWORK_INSTALLER_LINK = 'https://qgis.org/';

const OS_LIST = ['win64', 'mac', 'android', 'ios'];
const INSTALLER_DIST_TYPES = ['network', 'standalone'];

const extendProductsWithReleases = ({ releases, lang }) => {
  const productsWithInstallers = SOFTWARE_PRODUCTS.map((product) => {
    const installers = releases.reduce((installersAcc, release) => {
      if (product.installerName === release.name.toLowerCase()) {
        const optionsObject = release.options.reduce(
          (acc, { key, value }) => ({
            ...acc,
            [key]: value,
          }),
          {},
        );
        if (optionsObject.os && optionsObject.dist && release.files.length > 0) {
          return {
            ...installersAcc,
            [`${optionsObject.os}-${optionsObject.dist}`]: {
              external: false,
              fileId: release.files[0].id,
              fileName: release.files[0].name,
              link: release.files[0].link,
            },
          };
        }
        return installersAcc;
      }
      if (product.id === 'qgis' && lang !== 'ru') {
        if (installersAcc['win64-standalone']) delete installersAcc['win64-standalone'];
        installersAcc = {
          ...installersAcc,
          'win64-network': {
            external: true,
            fileId: null,
            fileName: null,
            link: VANILLA_QGIS_NETWORK_INSTALLER_LINK,
          },
        };
      }
      if (product.id === 'mobile')
        installersAcc = {
          ...installersAcc,
          'android-network': {
            external: true,
            fileId: null,
            fileName: null,
            link: MOBILE_NETWORK_ANDROID_INSTALLER_LINK,
          },
        };
      if (product.id === 'collector')
        installersAcc = {
          ...installersAcc,
          'android-network': {
            external: true,
            fileId: null,
            fileName: null,
            link: COLLECTOR_NETWORK_ANDROID_INSTALLER_LINK,
          },
        };
      if (product.id === 'tracker')
        installersAcc = {
          ...installersAcc,
          'android-network': {
            external: true,
            fileId: null,
            fileName: null,
            link: TRACKER_NETWORK_ANDROID_INSTALLER_LINK,
          },
        };
      return installersAcc;
    }, {});
    return { ...product, installers };
  });
  return productsWithInstallers;
};

export {
  SOFTWARE_PRODUCTS,
  MOBILE_NETWORK_ANDROID_INSTALLER_LINK,
  COLLECTOR_NETWORK_ANDROID_INSTALLER_LINK,
  VANILLA_QGIS_NETWORK_INSTALLER_LINK,
  OS_LIST,
  INSTALLER_DIST_TYPES,
  extendProductsWithReleases,
};
