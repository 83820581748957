const teams = {
  namespaced: true,
  state: () => ({
    ownTeam: undefined,
    nonOwnedTeams: undefined,
    isOwnTeamLoading: false,
    isNonOwnedTeamsLoading: false,
  }),
  mutations: {
    SET_OWN_TEAM(state, ownTeam) {
      state.ownTeam = ownTeam;
    },
    SET_NON_OWNED_TEAMS(state, nonOwnedTeams) {
      state.nonOwnedTeams = nonOwnedTeams;
    },
    SET_IS_OWN_TEAM_LOADING(state, isOwnTeamLoading) {
      state.isOwnTeamLoading = isOwnTeamLoading;
    },
    SET_IS_NON_OWNED_TEAMS_LOADING(state, isNonOwnedTeamsLoading) {
      state.isNonOwnedTeamsLoading = isNonOwnedTeamsLoading;
    },
  },
};

export default teams;
