import { memoize } from 'lodash-es';
import store from '@/store';
import * as teamApi from '@/api/teamApi';

const getNonOwnedTeams = async () => {
  const allTeams = await teamApi.getAllTeams();
  return allTeams
    .filter((team) => !team.owned_by_target_user && team.team_status !== 'disabled')
    .map((team) => {
      const ownerUsername = team.users.find(
        (user) => user.nextgis_guid === team.owner_id,
      )?.username;
      return { ...team, owner_username: ownerUsername };
    });
};

const memoizedGetNonOwnedTeams = memoize(getNonOwnedTeams);

const memoizedGetOwnTeam = memoize(teamApi.getOwnTeam);

const addMemberToTeam = async (newMember, allMembers) => {
  const usersWithoutOwner = allMembers.slice(1);
  const newTeamUsers = await teamApi.updateUsers([
    ...usersWithoutOwner,
    { nextgis_guid: newMember },
  ]);
  store.commit('teams/SET_OWN_TEAM', newTeamUsers);
  memoizedGetOwnTeam.cache.clear();
  return newTeamUsers;
};
const deleteMemberFromTeam = async (memberToDelete, allMembers) => {
  const newUsers = allMembers.filter(
    (user) => !user.owner && user.nextgis_guid !== memberToDelete.nextgis_guid,
  );
  const newTeamUsers = await teamApi.updateUsers(newUsers);
  store.commit('teams/SET_OWN_TEAM', newTeamUsers);
  memoizedGetOwnTeam.cache.clear();
  return newTeamUsers;
};

const loadNonOwnedTeamsToStore = async () => {
  try {
    // Save the team plan in localStorage to prevent the plan in the header from jumping.
    const localStorageTeamPlan =
      localStorage.getItem('teamPlan') !== '' ? JSON.parse(localStorage.getItem('teamPlan')) : null;
    if (typeof localStorageTeamPlan === 'boolean')
      store.commit('subscription/SET_TEAM_PLAN', localStorageTeamPlan);
    store.commit('teams/SET_IS_NON_OWNED_TEAMS_LOADING', true);
    const teams = await getNonOwnedTeams();
    store.commit('teams/SET_NON_OWNED_TEAMS', teams);

    // TODO: get teamPlan in subscriptionService from subscriptionApi
    const teamPlan = teams?.length > 0;

    if (teamPlan !== localStorageTeamPlan) {
      localStorage.setItem('teamPlan', teamPlan);
      store.commit('subscription/SET_TEAM_PLAN', teamPlan);
    }
  } finally {
    store.commit('teams/SET_IS_NON_OWNED_TEAMS_LOADING', false);
  }
};
const loadOwnTeamToStore = async () => {
  try {
    store.commit('teams/SET_IS_OWN_TEAM_LOADING', true);
    const ownTeam = await memoizedGetOwnTeam();
    store.commit('teams/SET_OWN_TEAM', ownTeam);
  } finally {
    store.commit('teams/SET_IS_OWN_TEAM_LOADING', false);
  }
};

export {
  getNonOwnedTeams,
  memoizedGetNonOwnedTeams,
  memoizedGetOwnTeam,
  addMemberToTeam,
  deleteMemberFromTeam,
  loadNonOwnedTeamsToStore,
  loadOwnTeamToStore,
};
