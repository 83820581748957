<template>
  <ngid-base-layout class="team-page" fluid>
    <div class="text-center" v-if="isOwnTeamLoading">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <div v-else>
      <h1 class="h5 mb-3 mt-n4">{{ $t('team.teamManagement') }}</h1>
      <ng-sheet xs outlined class="d-inline-block mb-10">
        <inner-menu :items="menuItems">
          <template v-slot:[`item.owner`]="{ item }">
            <v-badge v-if="hasOwnTeamError" color="error" dot>{{ item.text }}</v-badge>
          </template>
        </inner-menu>
      </ng-sheet>
      <router-view></router-view>
    </div>
    <slot></slot>
  </ngid-base-layout>
</template>

<script>
import { mapState } from 'vuex';
import * as teamService from '@/services/teamService';
import { fireError } from '@ngservices_front/services/errorService';
import NgidBaseLayout from '@/layouts/NgidBaseLayout';
import InnerMenu from '@/components/ui/InnerMenu';

export default {
  name: 'Team',
  components: {
    NgidBaseLayout,
    InnerMenu,
  },
  data() {
    return {
      menuItems: [
        {
          id: 'owner',
          text: this.$t('menu.owner'),
          to: '/teammanage/owner',
        },
        {
          id: 'member',
          text: this.$t('menu.member'),
          to: '/teammanage/member',
        },
      ],
    };
  },
  computed: {
    ...mapState('subscription', { plan: 'plan', isSubscriptionDataLoaded: 'isDataLoaded' }),
    ...mapState('teams', { team: 'ownTeam', isOwnTeamLoading: 'isOwnTeamLoading' }),
    hasOwnTeamError() {
      return this.team ? this.team.users.length > this.team['max_size'] : false;
    },
  },
  watch: {
    isSubscriptionDataLoaded: {
      async handler(value) {
        if (value && this.plan === 'premium') {
          try {
            teamService.loadOwnTeamToStore();
          } catch (e) {
            fireError(e);
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
